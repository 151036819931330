import selectAll from './select-all'
import baguetteBox from 'baguettebox.js'
import xhr from 'xhr'
import Swiper from 'swiper/dist/js/swiper'

function getItemInfo (id, element) {
  let body = document.getElementById(id).innerHTML
  document.getElementById('modal-body').innerHTML = body
  baguetteBox.run('.gallerymodal')
  modal(element)
}
export function modal (elem) {
  let closeButton = selectAll('.close')
  elem.classList.add('open')
  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[ i ].removeEventListener('click', function () {})
      closeButton[ i ].addEventListener('click', function () {
        elem.classList.remove('open')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) {
          window.location.reload()
        }
      }, false)
    }
  }
}
export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[ i ].removeEventListener('click', function () {})
      modals[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById(this.getAttribute('data-target'))
        if (this.classList.contains('modal-body')) {
          getItemInfo(this.getAttribute('data-id'), element)
        } else if (this.classList.contains('modal-anfrage')) {
          document.getElementById('anfrage-titel').value = this.getAttribute('data-titel')
          document.getElementById('anfrage-artNr').value = this.getAttribute('data-number')
          modal(element)
        } else {
          modal(element)
        }
        return false
      }, { passive: false })
    }
  }
  let modalsGallery = selectAll('.open-gallery')
  if (modalsGallery !== null) {
    for (var g = modalsGallery.length - 1; g >= 0; g--) {
      modalsGallery[g].removeEventListener('click', function () {
      })
      modalsGallery[g].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        xhr({
          body: JSON.stringify({
            modus: 'loadImageGalary',
            carID: this.getAttribute('data-id')
          }),
          uri: 'ajax.php',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }, (err, res, body) => {
          if (err) {
            console.log(err)
            return false
          } else {
            let data = JSON.parse(body)
            if (data.status === 'success') {
              let element = document.getElementById('modal-gallery')
              document.getElementById('modal-body-gallery').innerHTML = data.html
              modal(element)
              // eslint-disable-next-line
              new Swiper('.gallery-top', {
                loop: true,
                nextButton: '.gal-next',
                prevButton: '.gal-prev'
              })
            }
          }
        })
        return false
      }, {passive: false})
    }
  }
}
