import selectAll from './select-all'
import xhr from 'xhr'
import Swiper from 'swiper/dist/js/swiper'
import initToggle from './toggle-container'

function getVarinate () {
  let zoll
  if (document.getElementById('product--zoll')) {
    zoll = document.getElementById('product--zoll').value
  }

  let id = document.getElementById('product--id').value
  let eigSelect = document.getElementsByClassName('product--zoll--select')
  let data = {}
  for (let i = eigSelect.length - 1; i >= 0; i--) {
    data[i] = eigSelect[i].value
  }
  xhr({
    body: JSON.stringify({
      zoll: zoll,
      artID: id,
      eig: data,
      modus: 'getVariante'
    }),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err)
      return false
    } else {
      let data = JSON.parse(body)

      if (data.html) {
        document.getElementById('product--inner').innerHTML = data.html
        init()
        initToggle()
      }
    }
  })
}

function setZoll () {
  let _this = this
  let zollItems = selectAll('.product--zoll--item')

  if (zollItems !== null) {
    for (let i = zollItems.length - 1; i >= 0; i--) {
      zollItems[i].classList.remove('active')
    }
    _this.classList.add('active')
    document.getElementById('product--zoll').value = _this.getAttribute('data-id')
    getVarinate()
  }
}

export default function init () {
  let images = selectAll('.slide-product-image')
  if (images !== null) {
    for (let i = images.length - 1; i >= 0; i--) {
      images[i].removeEventListener('click', function () {
      })
      images[i].addEventListener('click', function (evt) {
        let _image = this.getAttribute('data-image')
        document.getElementById('product-image').innerHTML = '<img src="' + _image + '" class="pure-img">'
      })
    }
  }

  let zollItem = selectAll('.product--zoll--item')
  if (zollItem !== null) {
    for (let i = zollItem.length - 1; i >= 0; i--) {
      zollItem[i].removeEventListener('click', function () {
      })
      zollItem[i].addEventListener('click', setZoll)
    }
  }
  let slectItem = selectAll('.product--zoll--select')
  if (slectItem !== null) {
    for (let y = slectItem.length - 1; y >= 0; y--) {
      slectItem[y].removeEventListener('change', function () {
      })
      slectItem[y].addEventListener('change', getVarinate)
    }
  }

  var swiper = new Swiper('.product-sipwe', { // eslint-disable-line
    direction: 'vertical',
    slidesPerView: 4,
    spaceBetween: 5,
    navigation: {
      nextEl: '.product-next',
      prevEl: '.product-prev'
    }
  })
}
