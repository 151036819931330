var notifier = (function (w, d) {
  'use strict'

  var count = 0

  var myCreateElement = function (elem, attrs) {
    var el = d.createElement(elem)
    for (var prop in attrs) {
      el.setAttribute(prop, attrs[prop])
    }
    return el
  }

  var createContainer = function () {
    var container = myCreateElement('div', {class: 'notifier-container', id: 'notifier-container'})
    d.body.appendChild(container)
  }

  var show = function (title, msg, type, icon, timeout) {
    if (typeof timeout !== 'number') timeout = 0

    var ntfId = 'notifier-' + count

    var container = d.querySelector('.notifier-container')
    var ntf = myCreateElement('div', {class: 'notifier ' + type})
    var ntfTitle = myCreateElement('h2', {class: 'notifier-title'})
    var ntfBody = myCreateElement('div', {class: 'notifier-body'})
    var ntfImg = myCreateElement('div', {class: 'notifier-img'})
    var img = myCreateElement('img', {class: 'img', src: icon})
    var ntfClose = myCreateElement('button', {class: 'notifier-close', type: 'button'})

    ntfTitle.innerHTML = title
    ntfBody.innerHTML = msg
    ntfClose.innerHTML = '&times;'

    if (icon.length > 0) {
      ntfImg.appendChild(img)
    }

    ntf.appendChild(ntfClose)
    ntf.appendChild(ntfImg)
    ntf.appendChild(ntfTitle)
    ntf.appendChild(ntfBody)

    container.appendChild(ntf)

    ntfImg.style.height = ntfImg.parentNode.offsetHeight + 'px' || null

    setTimeout(function () {
      ntf.className += ' shown'
      ntf.setAttribute('id', ntfId)
    }, 100)

    if (timeout > 0) {
      setTimeout(function () {
        hide(ntfId)
      }, timeout)
    }

    ntfClose.addEventListener('click', function () {
      hide(ntfId)
    })

    count += 1

    return ntfId
  }

  var hide = function (notificationId) {
    var notification = document.getElementById(notificationId)

    if (notification) {
      notification.className = notification.className.replace(' shown', '')

      setTimeout(function () {
        notification.parentNode.removeChild(notification)
      }, 600)

      return true
    } else {
      return false
    }
  }

  createContainer()

  return {
    show: show,
    hide: hide
  }
})(window, document)

export default notifier
